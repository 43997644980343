import React from "react";
import ReactPlayer from 'react-player/vimeo';
import { Link } from "react-router-dom";
import Invertedlogo from './../../Logo-Inverted.png';
import Lines from './../../lines.png';



export default function Dashboard(props) {

  let previous_btn = "";
  if (props.previousPageurl) {
    previous_btn = <> <Link to={props.previousPageurl} className="float-md-left back-link" >
      <svg id="Layer" enable-background="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" /></svg> </Link></>
  }

  return (
    <>
        <header style={{position:"relative", zIndex:"2"}}>
          <nav style={{background:"transparent"}} className="navbar navbar-expand-lg custom-nav">
            <div className="container">
              <Link className="navbar-brand" to="/"  >
                <img src={Invertedlogo} width="110" alt="logo"/> 
              </Link>
              <ul className="navbar-nav ml-auto">
                <li>
                  <span onClick={props.clicked} >Logout</span>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <div className="welcome_container">

        <img className="welcome_lines" src={Lines}></img>


        <div className="welcome_inner_container">
          <h1 className="welcome_inner_header">You have successfully completed the Onboarding!</h1>
          <p className="welcome_inner_paragraph">Now notify @Nils Baumann in Slack that you have successfully completed the Onboarding. If you have any questions, you can of course also ask him.</p>
        </div>

        </div>
       



    </>
  );
}