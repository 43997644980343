import React from "react";
import InputColor from 'react-input-color';
import { withRouter } from "react-router-dom";
import Loader from "../../../src/loader.gif";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFormValid: true,
      afterloader: {},
      formData: {},
      freelancer_role: this.props.freelancer_role
    }
  }


  getToken() {
    const tokenString = localStorage.getItem('token');
    if (tokenString == null) {
      //need to redirect login
      this.props.history.push("/login");
    } else {
      return JSON.parse(tokenString).token;
    }
    //  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDM0ZWZkOTgwMzhjOTAwMTUwMmYyNzAiLCJpYXQiOjE2MTQwODIwMTB9.1VQZaQbiGsm1gkPzDmyE9obbMA9pfPY2T7GoorpWBms';
  }

  getForm() {
    
    return fetch('https://onboardingserver.herokuapp.com/api/freelancer/role', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    })
  }
  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

 

  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await this.getForm();
    const json = await response.json();

    console.log("ROLE ==> " , json.role)

    this.setState({ role: json.role });
    this.setState({ isLoading: false });

  }
  handleChange = (e) => {

    let freelancer_role = e.target.name

    this.setState({ freelancer_role: freelancer_role });
  }




  handleSubmit = (e) => {
    e.preventDefault();
      this.props.setFreelancerRole(this.state.freelancer_role)
      this.setState({ isLoading: true });
      fetch(`https://onboardingserver.herokuapp.com/api/freelancer/role?role=${this.state.freelancer_role}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.getToken()
        }
      }).then(response => {
        this.props.history.push("/dashboard-4");
      }).catch(error => {
        console.log(error);
      });
    this.setState({ isLoading: false });
  }

 

  render() {

    let formData = <div id="loading"><img id="loading-image" src={Loader} alt="Loading..." /></div>
    let afterloader = <div id="loading">123<img id="loading-image" src={Loader} alt="Loading..." /></div> 
    if (!this.state.isLoading) {
      formData = <form onSubmit={this.handleSubmit}>
        <div className="form-grouping-custom">
          <h3>Please select below which freelancer role applies to you. (Choose just one) </h3>
          <div className="form-group">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="designer" id="designer"
                value="true" 
                checked={this.state.freelancer_role == "designer" }
                onChange={this.handleChange} />
              <label className="form-check-label" for="designer"><h4>UI / UX Designer</h4></label>
            </div>
            <br></br>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="qa" id="qa"
                value="true" 
                checked={this.state.freelancer_role == "qa" }
                onChange={this.handleChange} />
              <label className="form-check-label" for="qa"><h4>Quality Assurance Engineer</h4></label>
            </div>
            <br></br>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="frontend" id="frontend"
                value="true" 
                checked={this.state.freelancer_role == "frontend" }
                onChange={this.handleChange} />
              <label className="form-check-label" for="frontend"><h4>Frontend Developer</h4></label>
            </div>
            <br></br>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="backend" id="backend"
                value="true" 
                checked={this.state.freelancer_role == "backend" }
                onChange={this.handleChange} />
              <label className="form-check-label" for="backend"><h4>Backend-Developer</h4></label>
            </div>
          </div>
        </div>
        
        <button type="submit" className="btn btn-primary submit-from w-100 mt-30">Save and go to the next step</button>

      </form>;
    }

    return (

      <>
        <section class="login-section2 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="heading text-center">
                  <h1 style={{fontFamily:"GTWALSHEIMPRO-MEDIUM"}}>Question</h1>
                </div>
                <div className="mt-30">
                  {formData}
                </div>
              </div>
              </div>
          </div>
        </section>

      </>

    )
  }
}
export default withRouter(Form) 