import React from "react";
import InputColor from 'react-input-color';
import { withRouter } from "react-router-dom";
import Loader from "../../../src/loader.gif";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFormValid: true,
      afterloader: {},
      formData: {},
      colorBtn: {
        color1: true,
        color2: false,
        color3: false,
      },
      colorBtnKey: 2
    }
  }

  addColors(param) {
    if (param <= 4) {
      let colorBtn = { ...this.state.colorBtn };
      let key = 'color' + param
      colorBtn[key] = true;
      this.setState({ colorBtn: colorBtn });
      param = param + 1;
      this.setState({ colorBtnKey: param });
    }
  }



  removeColor(param) {
    if (param <= 4) {
      let colorBtn = { ...this.state.colorBtn };
      let key = 'color' + param
      colorBtn[key] = false;
      this.setState({ colorBtn: colorBtn });
      this.setState({ colorBtnKey: param });
    }
  }



  getToken() {
    const tokenString = localStorage.getItem('token');
    if (tokenString == null) {
      //need to redirect login
      this.props.history.push("/login");
    } else {
      return JSON.parse(tokenString).token;
    }
    //  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDM0ZWZkOTgwMzhjOTAwMTUwMmYyNzAiLCJpYXQiOjE2MTQwODIwMTB9.1VQZaQbiGsm1gkPzDmyE9obbMA9pfPY2T7GoorpWBms';
  }

  getForm() {
    
    return fetch('https://onboardingserver.herokuapp.com/api/freelancer/transparency-questions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    })
  }
  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }



  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await this.getForm();
    const json = await response.json();

    console.log("JSON ==> "  + JSON.stringify(json))

    this.setState({ formData: json });
    this.setState({ isLoading: false });
  }
  handleChange = (e) => {
    let field_name_group = e.target.name.split('.');
    let group_name = field_name_group[0];
    let input_name = field_name_group[1];
    let formData = { ...this.state.formData };
    let userResponse = e.target.value.trim();



    console.log(field_name_group)



    if (this.isEmpty(userResponse)) {
      formData[group_name][input_name] = userResponse;
    } else {
      // set value in formData state
      formData[group_name][input_name] = e.target.value;
    }
    this.setState({ formData: formData });
  }

  validateForm() {
    let formData = { ...this.state.formData };
    let isError = false;
    
    if (this.isEmpty(formData.design.corporate_design.answer)) {
      formData.design.corporate_design.error = 'Dieses Feld ist erforderlich';
      console.log("1 isError = true")
      isError = true;
    } else {
      formData.design.corporate_design.error = '';
    }

 

    if (this.isEmpty(formData.references.link1.answer)) {
      formData.references.link1.error = 'Dieses Feld ist erforderlich';
      console.log("4 isError = true")
      isError = true;
    } else {
      if(this.isValidURL(formData.references.link1.answer)){
        formData.references.link1.error = '';
      }else{
        formData.references.link1.error = 'Please enter a valid URL.';
        isError = true;
        console.log("5 isError = true")
      }
      
    }

    if (this.isEmpty(formData.references.link2.answer)) {
      formData.references.link2.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("6 isError = true")
    } else {
      if(this.isValidURL(formData.references.link2.answer)){
        formData.references.link2.error = '';
      }else{
        formData.references.link2.error = 'Please enter a valid URL.';
        isError = true;
        console.log("7 isError = true")
      }
    }

    if (this.isEmpty(formData.references.link3.answer)) {
      formData.references.link3.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("8 isError = true")
    } else {
      if(this.isValidURL(formData.references.link3.answer)){
        formData.references.link3.error = '';
      }else{
        formData.references.link3.error = 'Please enter a valid URL.';
        isError = true;
        console.log("9 isError = true")
      }
    }

    if (this.isEmpty(formData.references.priority1.answer)) {
      formData.references.priority1.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("10 isError = true")
    } else {
      formData.references.priority1.error = '';
    }

    if (this.isEmpty(formData.references.priority2.answer)) {
      formData.references.priority2.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("11 isError = true")
    } else {
      formData.references.priority2.error = '';
    }

    if (this.isEmpty(formData.references.priority3.answer)) {
      formData.references.priority3.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("12 isError = true")
    } else {
      formData.references.priority3.error = '';
    }

    if (this.isEmpty(formData.references.notice1.answer)) {
      formData.references.notice1.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("13 isError = true")
    } else {
      formData.references.notice1.error = '';
    }

    if (this.isEmpty(formData.references.notice2.answer)) {
      formData.references.notice2.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("14 isError = true")
    } else {
      formData.references.notice2.error = '';
    }

    if (this.isEmpty(formData.references.notice3.answer)) {
      formData.references.notice3.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("15 isError = true")
    } else {
      formData.references.notice2.error = '';
    }


   

    this.setState({ formData: formData });
    if (isError) {
      this.setState({ isFormValid: false });
    }
    return new Promise((resolve, reject) => {
      resolve(isError);
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();


      this.setState({ isLoading: true });
      fetch('https://onboardingserver.herokuapp.com/api/freelancer/transparency-questions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.getToken()
        },
        body: JSON.stringify(this.state.formData.transparency_questions)
      }).then(response => {
        this.props.history.push("/dashboard-6");
      }).catch(error => {
        console.log(error);
      });
    
    this.setState({ isLoading: false });
  }

 

  render() {

    let formData = <div id="loading"><img id="loading-image" src={Loader} alt="Loading..." /></div>
    let afterloader = <div id="loading">123<img id="loading-image" src={Loader} alt="Loading..." /></div> 
    if (!this.state.isLoading) {
      formData = <form onSubmit={this.handleSubmit}>
        <div className="form-grouping-custom">
          <h3>Questions for more Transparency</h3>


          <div className="form-group">

            <div className="input-and-headings">
              <h4>Do you have a steady job? (Please be honest, because whether you have one doesn't matter to me)</h4>
              <textarea name="transparency_questions.steady_job" className="w-100"
                placeholder={this.state.formData.transparency_questions.steady_job}
                value={this.state.formData.transparency_questions.steady_job} onChange={this.handleChange}></textarea>
              <div className="errorMsg"></div>
            </div>

            <div className="input-and-headings">
              <h4>If so, when do you have to work for this job?</h4>
              <textarea name="transparency_questions.time_job" className="w-100"
                placeholder={this.state.formData.transparency_questions.time_job}
                value={this.state.formData.transparency_questions.time_job} onChange={this.handleChange}></textarea>
              <div className="errorMsg"></div>
            </div>

            <div className="input-and-headings">
              <h4>From when to when do you work as a freelancer?</h4>
              <textarea name="transparency_questions.work_as_freelancer" className="w-100"
                placeholder={this.state.formData.transparency_questions.work_as_freelancer}
                value={this.state.formData.transparency_questions.work_as_freelancer} onChange={this.handleChange}></textarea>
              <div className="errorMsg"></div>
              
            </div>

            <div className="input-and-headings">
              <h4>How many other projects do you work on on average?</h4>
              <textarea name="transparency_questions.project_amount" className="w-100"
                placeholder={this.state.formData.transparency_questions.project_amount}
                value={this.state.formData.transparency_questions.project_amount} onChange={this.handleChange}></textarea>
              <div className="errorMsg"></div>
              
            </div>

            <div className="input-and-headings">
              <h4>What time zone are you in exactly?</h4>
              <textarea name="transparency_questions.time_zone" className="w-100"
                placeholder={this.state.formData.transparency_questions.time_zone}
                value={this.state.formData.transparency_questions.time_zone} onChange={this.handleChange}></textarea>
              <div className="errorMsg"></div>
              
            </div>

            <div className="input-and-headings">
              <h4>From when to when to when can you best be reached?</h4>
              <textarea name="transparency_questions.time_contact" className="w-100"
                placeholder={this.state.formData.transparency_questions.time_contact}
                value={this.state.formData.transparency_questions.time_contact} onChange={this.handleChange}></textarea>
              <div className="errorMsg"></div>
              
            </div>

          </div>


        </div>


        
        
        <button type="submit" className="btn btn-primary submit-from w-100 mt-30">Save and go to the next step</button>

      </form>;
    }

    return (

      <>
        <section class="login-section2 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="heading text-center">
                  <h1></h1>
                </div>
                <div className="mt-30">
                  {formData}
                </div>
              </div>
              </div>
          </div>
        </section>

      </>

    )
  }
}
export default withRouter(Form) 