import React from "react";
import InputColor from 'react-input-color';
import { withRouter } from "react-router-dom";
import Loader from "../../../src/loader.gif";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isFormValid: true,
      afterloader: {},
      formData: {},
      completion_code: "",
      error: null
    }
  }





  getToken() {
    const tokenString = localStorage.getItem('token');
    if (tokenString == null) {
      //need to redirect login
      this.props.history.push("/login");
    } else {
      return JSON.parse(tokenString).token;
    }
    //  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDM0ZWZkOTgwMzhjOTAwMTUwMmYyNzAiLCJpYXQiOjE2MTQwODIwMTB9.1VQZaQbiGsm1gkPzDmyE9obbMA9pfPY2T7GoorpWBms';
  }






  handleChange = (e) => {
    
    let userCode = e.target.value

    console.log(userCode)

    if (userCode != null) {
      
      this.setState({ completion_code: userCode });

    } else {

      console.log("ERROR")
    }
  }


  handleSubmit = (e) => {
    e.preventDefault();


      this.setState({ isLoading: true });
      fetch(`https://onboardingserver.herokuapp.com/api/freelancer/completion-code?code=${this.state.completion_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.getToken()
        }
      }).then(response => {

        response.json().then(json => {
          
          console.log(json)

          let completion_state = json.completion

          if(completion_state){

            this.setState({ error: null });
            this.props.history.push("/dashboard-success");


          }else{
            this.setState({ error: "The code which you entered is wrong. Please watch the videos carefully again and try it again!" });
          }

        }).catch(error => {
          console.log(error);
          this.setState({ error: "Something went wrong. Please try it again!" });
        });

      }).catch(error => {
        console.log(error);
        this.setState({ error: "Something went wrong. Please try it again!" });
      });
    
    this.setState({ isLoading: false });
  }

 

  render() {

    let formData = <div id="loading"><img id="loading-image" src={Loader} alt="Loading..." /></div>
    let afterloader = <div id="loading">123<img id="loading-image" src={Loader} alt="Loading..." /></div> 
    if (!this.state.isLoading) {
      formData = <form onSubmit={this.handleSubmit}>
        <div className="form-grouping-custom">
          <h3>Completion of your On Boarding</h3>


          <div className="form-group">

            <div className="input-and-headings">
              <h4>Please enter the 6-digit code that I gave you in the course of these steps. (Please use capital letters)</h4>
              <input name="transparency_questions.steady_job" className="w-100"
                placeholder="X43Y0Z"
                value={this.state.completion_code} onChange={this.handleChange}></input>
              <div className="errorMsg">{this.state.error}</div>
            </div>
          </div>


        </div>


        
        
        <button type="submit" className="btn btn-primary submit-from w-100 mt-30">Save and go to the next step</button>

      </form>;
    }

    return (

      <>
        <section class="login-section2 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="heading text-center">
                  <h1></h1>
                </div>
                <div className="mt-30">
                  {formData}
                </div>
              </div>
              </div>
          </div>
        </section>

      </>

    )
  }
}
export default withRouter(Form) 