import Invertedlogo from './Logo-Inverted.png';
import { Link } from "react-router-dom";
import React from "react";



const Header = (props) => {
  
  return (
       <>
        <header>
          <nav className="navbar navbar-expand-lg custom-nav">
            <div className="container">
              <Link className="navbar-brand" to="/"  >
                <img src={Invertedlogo} width="110" alt="logo"/> 
              </Link>
              <ul className="navbar-nav ml-auto d-none d-md-block">
                <li className="d-flex">
                  <span onClick={props.clicked} >Logout</span>
                </li>
              </ul>
            </div>
          </nav>
        </header>
       </>
     );
};

export default Header;
