import { Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './Header';
import OuterHeader from './OuterHeader';

import Dashboard from './components/Dashboard/Dashboard';
import DashboardNav from './components/Dashboard/DashboardNav';
import Login from './components/Login/Login';
import Preferences from './components/Preferences/Preferences';
import useToken from './components/App/useToken';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';

import Form_FreelancerType from './components/Form/Form_FreelancerType'
import Form_Transparency from './components/Form/Form_Transparency'


import WelcomeScreen from './components/WelcomeScreen/WelcomeScreen'


import Meetings from './components/Meetings/Meetings'
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import Form_Completion from './components/Form/Form_Completion';


const App = () => {
  const { token, setToken } = useToken();

  const [ freelancer_role, setFreelancerRole ] = useState("");


  let history = useHistory();
  const logout = () => {
    localStorage.removeItem('token');
    history.push('/');
    setToken('');
  }



  function getRole(token) {

    console.log(token)
    
  
    fetch('https://onboardingserver.herokuapp.com/api/freelancer/role', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
        }).then(response => {
  
            if (response.ok) {
            response.json().then(json => {
              console.log("RESPONSE ENDED SUCCESSFULLY ==> " + JSON.stringify(json));

              setFreelancerRole(json.role)
            });
          }
        }).catch(error => {
          console.log(error);
        });
  }


  useEffect(() => {
    getRole(token)
  })



  let homeScreen = <Switch>
    <Route exact path="/">
      <OuterHeader />
      <Login setToken={setToken} />
    </Route>
    <Route path="/forgot-password">
      <OuterHeader />
      <ForgotPassword />
    </Route>
    <Route path="*">
      <OuterHeader />
      <Preferences />
    </Route>
  </Switch>

  if (token) {
    homeScreen = <Switch>
      <Route exact path="/">
        <Header clicked={() => logout()} />
        <Dashboard description={null}
                    url="https://player.vimeo.com/video/568980093?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=56ba2cd4f0" previousPageurl="" title={[<b> Step 1:</b>, " How the Onboarding process works?"]} />
              <DashboardNav text="Go to the next step" NextPageurl="dashboard-2" />
      </Route>

      <Route exact path="/dashboard-2">
        <Header clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980282?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e074ffdc86" previousPageurl="/" title={[<b> Step 2:</b>, " Who is 7GB?"]} />
        <DashboardNav text="Go to the next step" NextPageurl="dashboard-3" />
      </Route>
      <Route exact path="/dashboard-3">
        <Header clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980444?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=331f883f86" previousPageurl="dashboard-2" title={[<b> Step 3:</b>, " What type of freelancer are you?"]} />
        <Form_FreelancerType freelancer_role={freelancer_role} setFreelancerRole={(role) => setFreelancerRole(role)} />
      </Route>

      <Route exact path="/dashboard-4">
        <Header clicked={() => logout()} />
        {freelancer_role == "designer" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-3" title={[<b> Step 4:</b>, " Communication requirements for UI/ UX Designer"]} />) : null}
        {freelancer_role == "qa" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-3" title={[<b> Step 4:</b>, " Communication requirements for Quality Assurance Engineer"]} />) : null}
        {freelancer_role == "frontend" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-3" title={[<b> Step 4:</b>, " Communication requirements for Frontend Developer"]} />) : null}
        {freelancer_role == "backend" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-3" title={[<b> Step 4:</b>, " Communication requirements for Backend Developer"]} />) : null}

        <DashboardNav text="Go to the next step" NextPageurl="dashboard-5" />
      </Route>
      
      <Route exact path="/dashboard-5">
        <Header clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-4" title={[<b> Step 5:</b>, " Questions for more clarity"]} />
        <Form_Transparency />
      </Route>

      <Route exact path="/dashboard-6">
        <Header clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-5" title={[<b> Step 6:</b>, " The whole work process"]} />
        <DashboardNav text="Go to the next step" NextPageurl="dashboard-7" />
      </Route>

      <Route exact path="/dashboard-7">
        <Header clicked={() => logout()} />
        {freelancer_role == "designer" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-6" title={[<b> Step 7:</b>, " How does your job as a UI / UX designer look like?"]} />) : null}
        {freelancer_role == "qa" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-6" title={[<b> Step 7:</b>, " How does your job as a QA Engineer look like?"]} />) : null}
        {freelancer_role == "frontend" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-6" title={[<b> Step 7:</b>, " How does your job as a Frontend Devoloper look like?"]} />) : null}
        {freelancer_role == "backend" ? (<Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-6" title={[<b> Step 7:</b>, " How does your job as a Backend Developer look like?"]} />) : null}

        <DashboardNav text="Go to the next step" NextPageurl="dashboard-8" />
      </Route>
      
      <Route exact path="/dashboard-8">
        <Header clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-71" title={[<b> Step 8:</b>, " The right mindset for a successful collaboration"]} />
        <DashboardNav text="Go to the next step" NextPageurl="dashboard-9" />
      </Route>

      <Route exact path="/dashboard-9">
        <Header clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-8" title={[<b> Step 9:</b>, " Payment & legal"]} />
        <DashboardNav text="Go to the next step" NextPageurl="dashboard-10" />
      </Route>

      <Route exact path="/dashboard-10">
        <Header clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-9" title={[<b> Step 10:</b>, " What can our future together look like?"]} />
        <Form_Completion />
      </Route>

      
      <Route exact path="/dashboard-success">
      {/* <Header clicked={() => logout()} /> */}
        <WelcomeScreen clicked={() => logout()}></WelcomeScreen>
      </Route>
      
      






    

      <Route path="*">
        <Header clicked={() => logout()} />
        <Preferences />
      </Route>
    </Switch>
  }

  return (
    <>
      {homeScreen}
    </>
  );
};

export default App;